$ = jQuery
class @Film
  constructor: (filmsDir, filmName) ->
    self = @
    fullQualifiedFileName = '/' + filmsDir + '/' + filmName + '/' + filmName
#    googleAnalyticsTrackingCode = 'UA-25855606-5'

    flowplayer (api, root) ->
      api.on('resume', ->
        self.pauseAllHtml(api.video.src)
      )

      api.on('ready', ->
        self.pauseAllHtml(api.video.src)
      )

    filmbox = $('#' + filmName + '-box')
    #filmboxResizer = new FilmboxResizer(filmbox) # Jürgen Hoffmann didn't want this
    flowplayer(filmbox, {
      key: "$822490449573258",
      autoplay: true
      clip: {
        qualities: ["144p", "240p", "360p", "480p", "720p", "1080p"],
        defaultQuality: "360p",
        sources: [
          {
            type: "video/mp4"
            src: fullQualifiedFileName + '.mp4'
          }
        ]
      }
    })
#    flowplayer.conf.analytics = googleAnalyticsTrackingCode

  pauseAllHtml: (src) ->
    $('.flowplayer').each( (index, elem) ->
      currentInstance = flowplayer(index)
      if src != currentInstance.video.src
        player = flowplayer(index)
        player.pause()
    )